import checkPlenigoSession from './modules/plenigoSession';
import loginLinkListener from './modules/loginLinkListener';
import checkPlenigoReadingAccess from './modules/plenigoReadingAccess';
import submitUserInfoToEtracker from './modules/tracking';
import {trackLoginEvent, trackRegisterEvent} from './modules/checkoutTracking';

window.addEventListener('load', checkPlenigoSession());
window.addEventListener('load', checkPlenigoReadingAccess());
window.addEventListener('load', loginLinkListener());
window.addEventListener('load', submitUserInfoToEtracker());
document.addEventListener('plenigo.defaultStepSuccessRegister', trackLoginEvent);
document.addEventListener('plenigo.defaultStepSuccessLogin', trackRegisterEvent);
