/* eslint-disable no-underscore-dangle */

// eslint-disable no-underscore-dangle
export function trackLoginEvent() {
    if (typeof window._etracker !== 'object') {
        return;
    }

    window._etracker.sendEvent({
        name: 'defaultStepSuccessLogin',
        category: 'Plenigo SSO',
        action: 'Login',
        label: 'Loginformular',
        value: 1,
    });
}

export function trackRegisterEvent() {
    if (typeof window._etracker !== 'object') {
        return;
    }

    window._etracker.sendEvent({
        name: 'defaultStepSuccessRegister',
        category: 'Plenigo SSO',
        action: 'Registrierung',
        label: 'Registrierungsformular',
        value: 1,
    });
}
