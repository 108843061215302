import {checkCookie} from '../utils/cookies';
import checkPlenigoSession from './plenigoSession';

function getPostIdFromBody() {
    const {body} = document;

    const hasPostIdClass = Array.from(body.classList).some((className) => className.startsWith('postid-'));
    if (hasPostIdClass && body.classList.contains('is-paid-user')) {
        const postClass = Array.from(body.classList).find((cls) => /^postid-\d+$/.test(cls));

        if (postClass) {
            const postId = postClass.split('-')[1];
            return parseInt(postId, 10);
        }
    }

    return 0;
}

export default function checkPlenigoReadingAccess() {
    const isPaidUser = document.querySelector('body').classList.contains('is-paid-user');
    const id = getPostIdFromBody();

    if (checkCookie('juve_reading_check_') && isPaidUser && id !== 0) {
        fetch(`/wp-json/auth/v1/autorisation/?postId=${id}`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'User-Agent': navigator.userAgent,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (!data.status && data.has_paywall) {
                    // eslint-disable-next-line no-new
                    const event = new CustomEvent('juvePlusError', {
                        detail: data,
                    });

                    document.dispatchEvent(event);

                    checkPlenigoSession();
                }
            })
            .catch((error) => {
                console.error('Fehler beim Abrufen der Session-Information:', error);
            });
    }
}
