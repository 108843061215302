import {setCookie} from '../utils/cookies';

const cookieName = 'juve_paid_login_referer';

export default function loginLinkListener() {
    /**
     * Der User soll nach dem Login wieder auf die Seite zurückgeführt werden, von der er kam.
     * Um das zu realisieren wird beim Click auf den Login-Link ein Cookie mit der letzten URL gesetzt.
     */
    document.addEventListener('click', (event) => {
        if (event.target.closest('.js-paid-redirect-login')) {
            setCookie(cookieName, window.location.href);
        }
    });
}
