export function checkCookie(cookieName) {
    const cookies = document.cookie.split(';');

    return cookies.some((cookie) => cookie.trim().startsWith(`${cookieName}`));
}

export function getCookie(cookieName) {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(';');

    const cookie = cookies.find((cookieEntry) => cookieEntry.trim().startsWith(name));

    if (cookie) {
        return cookie.trim().substring(name.length);
    }
    return null;
}

export function setCookie(cookieName, cookieValue, daysToExpire = 0) {
    let expires = '';

    if (daysToExpire !== 0) {
        const date = new Date();
        date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
        expires = `expires=${date.toUTCString()};`;
    }

    document.cookie = `${cookieName}=${cookieValue};${expires}path=/`;
}

export function deleteCookie(cookieName) {
    const date = new Date(0);
    document.cookie = `${cookieName}=; expires=${date.toUTCString()}; path=/`;
}
