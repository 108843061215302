import {checkCookie, deleteCookie} from '../utils/cookies';

function toggleItem(item, shouldShow) {
    if (item) {
        item.classList.toggle('secondary-navigation__item--hidden', !shouldShow);
    }
}

function removeJuvePlusSessionCookie() {
    if (checkCookie('juve_plus_session')) {
        document.cookie = 'juve_plus_session=; Max-Age=0; path=/';
    }

    if (checkCookie('juve_plus_remember')) {
        document.cookie = 'juve_plus_remember=; Max-Age=0; path=/';
    }
}

export default function checkPlenigoSession() {
    removeJuvePlusSessionCookie();

    if (checkCookie('juve_login_session')) {
        const url = new URL('/wp-json/auth/v1/authentication/', window.location.origin);

        const match = document.body.className.match(/postid-(\d+)/);
        const postId = match ? match[1] : null;

        if(postId) {
            url.searchParams.set('postId', postId);
        }

        const paywallAnchor = document.querySelector('.js-paywall-information');
        if(paywallAnchor) {
            url.searchParams.set('paywall', '1');
        }

        fetch(url, {
            method: 'GET', credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                const login = document.querySelector('.js-header-login');
                const profil = document.querySelector('.js-header-profile');
                const logout = document.querySelector('.js-header-logout');

                if (data.status) {
                    toggleItem(login, false);
                    toggleItem(profil, true);
                    toggleItem(logout, true);

                    return;
                }

                toggleItem(login, true);
                toggleItem(profil, false);
                toggleItem(logout, false);

                deleteCookie('sso_session');
                deleteCookie('juve_plus');
            })
            .catch((error) => {
                console.error('Fehler beim Abrufen der Session-Information:', error);
            });
    }
}
